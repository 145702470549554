<template>
    <el-card class="manageBox">
        <!-- 搜索 -->
        <div class="top">
            <el-form :inline="true" :model="searchParams" label-width="100px" class="demo-form-inline">
                <el-form-item label="选择月份">
                    <el-date-picker class="w200px" v-model="searchParams.serviceDate" value-format="yyyy-MM" type="month" placeholder="请选择开始服务月份"> </el-date-picker>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select class="w200px" v-model="searchParams.status" clearable placeholder="请选择状态">
                        <el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="服务内容">
                    <el-select class="w200px" v-model="searchParams.serviceContent" clearable placeholder="请选择服务内容">
                        <el-option v-for="item in contentList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属区划">
                    <el-cascader
                        clearable
                        placeholder="请选择所属区划"
                        v-model="streetArr"
                        :props="defaultProps"
                        class="w200px"
                        ref="supervisedUnitCode"
                        :show-all-levels="false"
                        filterable
                        :options="streetList"
                    ></el-cascader>
                </el-form-item>
                <el-form-item label="服务机构">
                    <el-select class="w200px" v-model="searchParams.sysId" placeholder="请选择服务机构" filterable clearable>
                        <el-option v-for="item in organList" :label="item.serviceName" :value="item.id" :key="item.id"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="服务对象类别">
                    <el-autocomplete
                        class="w200px"
                        clearable
                        v-model="searchParams.objectName"
                        :fetch-suggestions="querySearchAsync"
                        placeholder="请输入服务对象类别"
                    ></el-autocomplete>
                </el-form-item>
                <el-form-item label="关键字">
                    <el-input v-model="searchParams.keyWord" clearable placeholder="请输入关键字" style="width: 250px" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" class="search" @click="doSearch">搜索</el-button>
                </el-form-item>
                <el-form-item style="float: right">
                    <el-button type="primary" class="add" @click="getDown">导出</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- 表格 -->
        <el-table v-loading="loading" class="table" :data="tableData" border style="width: 100%" :header-cell-style="headerCellStyle">
            <template slot="empty">
                <TableEmpty />
            </template>
            <el-table-column type="index" width="50" label="序号" />
            <el-table-column prop="serviceStartTime" label="开始服务时间" />
            <el-table-column prop="userName" label="姓名" />
            <el-table-column prop="cardId" label="身份证号" />
            <el-table-column prop="phone" label="联系电话" />
            <el-table-column prop="old" label="年龄" />
            <el-table-column prop="sex" label="性别">
                <template slot-scope="scope">
                    <span>{{ scope.row.sex === 0 ? '女' : '男' }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="orgName" label="所属区划">
                <template slot-scope="scope">
                    <span>{{ `${scope.row.streetName}/${scope.row.orgName}` }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="homeAddress" label="家庭地址" />
            <el-table-column prop="objectName" label="服务对象类别" align="center" :show-overflow-tooltip="true" />
            <el-table-column prop="objectServiceContent" label="服务内容" width="150">
                <template slot-scope="scope">
                    <span>{{ formatServiceContent(scope.row.objectServiceContent) }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="状态" :show-overflow-tooltip="true" />
            <el-table-column prop="serviceName" label="服务机构" :show-overflow-tooltip="true" />
            <el-table-column label="操作" fixed="right">
                <template slot-scope="scope">
                    <el-button
                        type="text"
                        @click="
                            showDetail = true
                            currentId = scope.row.id
                        "
                        >查看</el-button
                    >
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
            v-if="tableData.length > 0"
            style="margin-top: 20px"
            background
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pageParams.pageSize"
            :current-page="pageParams.pageNum"
            layout="total, sizes, prev, pager, next"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        />
        <!-- 查看详情 -->
        <el-drawer
            v-if="showDetail"
            title="查看详情"
            :visible="showDetail"
            :direction="`rtl`"
            size="50%"
            :before-close="
                () => {
                    showDetail = false
                }
            "
        >
            <DetailInfo :id="currentId" />
        </el-drawer>
    </el-card>
</template>

<script>
import { streetList, serviceNameList, backCascader2, getServiceObjectTypeList } from '@/api/orderManage'
import { downloadStream } from '@/utils/index'
import DetailInfo from './detailInfo.vue'
import { pageList, excelDow } from '@/api/pensionService'
import { AUDITSTATE, SERVICECONTENT } from '@/data/dataDictionary'
export default {
    // 人员列表
    name: 'PersonnelList',
    components: { DetailInfo },
    data() {
        return {
            showDetail: false, // 查看详情
            loading: false,
            total: 0,
            pageParams: {
                // 分页参数
                pageNum: 1,
                pageSize: 10
            },
            statusOptions: [
                { label: '未停发', value: '未停发' },
                { label: '已停发', value: '已停发' }
            ],
            currentPerson: null,
            currentId: '',
            // total: 0,
            // pageNum: 1,
            // pageSize: 10,
            // loading: false,
            options: [],
            tableData: [],
            streetList: [],
            streetArr: [],
            organList: [],
            comboOptions: [],
            searchParams: {
                serviceDate: '',
                status: '',
                serviceContent: '',
                orgId: '',
                sysId: '',
                objectName: '',
                keyWord: ''
            },
            defaultProps: {
                value: 'streetId',
                label: 'streetName',
                children: 'streetNameListResultList',
                checkStrictly: true
            },
            contentList: [
                { label: '助老服务', value: 1 },
                { label: '信息服务', value: 2 }
            ],
            occupancyCategoryList: [
                { label: '城镇', value: 0 },
                { label: '农村', value: 1 }
            ],
            maritalList: [
                { label: '已婚', value: 1 },
                { label: '未婚', value: 2 },
                { label: '离异', value: 3 },
                { label: '丧偶', value: 4 }
            ],
            livingAbilityList: [
                { label: '自理', value: 1 },
                { label: '半失能', value: 2 },
                { label: '全失能', value: 3 }
            ],
            relationList: [
                { label: '父子(女)', value: 1 },
                { label: '母子(女)', value: 2 },
                { label: '夫妻', value: 3 },
                { label: '兄弟姐妹', value: 4 },
                { label: '祖孙', value: 5 }
            ],
            sexList: [
                { label: '女', value: 0 },
                { label: '男', value: 1 }
            ]
        }
    },
    mounted() {
        // this.initDate()
        this.getStreetList()
        this.getOrganList()
        this.getServiceList()
        this.getList()
    },
    methods: {
        initDate() {
            let date = new Date()
            let nowYear = date.getFullYear()
            let nowMonth = date.getMonth() + 1
            if (nowMonth >= 1 && nowMonth <= 9) {
                nowMonth = '0' + nowMonth
            }
            this.searchParams.serviceDate = `${nowYear}-${nowMonth}`
        },
        // 获取街道列表
        getStreetList() {
            streetList().then(res => {
                this.streetList = res.data
            })
        },
        // 获取机构列表
        getOrganList() {
            serviceNameList().then(res => {
                this.organList = res.data
            })
        },
        // 获取服务项目列表
        getServiceList() {
            backCascader2().then(res => {
                this.comboOptions = res.data
            })
        },
        formatServiceContent(vals) {
            let arr = vals.split(',')
            let newArr = arr.map(element => {
                let val = SERVICECONTENT.find(item => item.value === Number(element)).label
                return val
            })
            return newArr.join()
        },
        querySearchAsync(queryString, cb) {
            let result = []
            getServiceObjectTypeList({ serviceObjectType: queryString }).then(res => {
                if (res.data.length) {
                    res.data.forEach(item => {
                        result.push({
                            value: item,
                            id: item
                        })
                    })
                }
            })
            cb(result)
        },
        getIndex($index) {
            return (this.pageParams.pageNum - 1) * this.pageParams.pageSize + $index + 1
        },
        // 分页
        handleSizeChange(size) {
            this.pageParams.pageSize = size
            this.doSearch()
        },
        handleCurrentChange(page) {
            this.pageParams.pageNum = page
            this.getList()
        },
        // 获取订单列表
        async getList(sortParams) {
            this.loading = true
            this.searchParams.orgId = this.streetArr[this.streetArr.length - 1]
            const data = Object.assign({ ...this.searchParams, ...this.pageParams })
            const res = await pageList(data)
            this.tableData = res.data.records
            this.total = res.data.total
            this.loading = false
        },
        // 搜索
        doSearch() {
            this.pageParams.pageNum = 1
            this.getList()
        },
        // 导出下载
        async getDown() {
            this.searchParams.orgId = this.streetArr[this.streetArr.length - 1]
            const data = Object.assign({ ...this.searchParams, ...this.pageParams })
            const res = await excelDow(data)
            downloadStream(res, '居家养老服务-人员列表.xls')
        }
    }
}
</script>

<style lang="scss" scoped>
/deep/ .el-drawer__header {
    padding: 20px 0 20px 0;
    font-size: 25px;
    border-bottom: 1px solid #ccc;
    &::before {
        content: '';
        width: 9px;
        height: 30px;
        background: #3fcf89;
        float: left;
        margin-right: 20px;
    }
}
.manageBox {
    /*margin: 20px;*/
    background: #fff;
    min-height: calc(100vh - 130px);
}
// .statisticsElderlyServices {
//   .header-box {
//     .search {
//       display: flex;
//       justify-content: flex-start;
//       flex-wrap: wrap;
//     }
//   }
// }

.btn {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.orderColor {
    color: #f02929;
}
.w200px {
    width: 250px;
}
</style>
